import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';
import Footer from '../components/Footer';

const SuccessPage = () => {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      
      console.log('Retrieved session ID:', sessionId);

      if (!sessionId) {
        console.error('Session ID is missing. Redirecting the user to payment failure page.');
        return; // Optionally redirect the user to an error or retry page
      }

      const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
      const appUrl = process.env.REACT_APP_APP_URL;

      // Determine if the user is a Google user
      const isGoogleUser = user.sub.startsWith("google");

      // Extract name and username depending on user type
      const name = isGoogleUser ? user.name : user[`${appUrl}/name`];
      const username = isGoogleUser ? user.nickname : user.username;

      console.log('User Details:', { name, username });

      const upgrade = urlParams.get('upgrade')?.toLowerCase() === 'true';
      // Prepare data for the backend
      const requestData = {
        email: user.email,
        user_id: user.sub,
        session_id: sessionId,
        payment_status: 'succeeded',
        name: name,
        username: username,
        plan: "unknown", // Backend determines the actual plan
        upgrade: upgrade,
      };

      console.log('Upgrade flag:', upgrade);

      axios
        .post(`${paymentApiUrl}/store-payment-info`, requestData)
        .then(response => {
          console.log('Payment info stored successfully:', response.data);
        })
        .catch(error => {
          console.error('Error storing payment info:', error.response?.data || error.message);
          // Optionally notify the user or redirect to a support/contact page
        });
    }
  }, [user]);

  if (isLoading) {
    return <CircularProgress style={{ marginTop: '100px' }} />;
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px', paddingBottom: '100px' }}>
      <Box mt={10}>
        <Typography variant="h4" align="center">
          <strong>Sikeres fizetés!</strong>
        </Typography>
        <Typography mt={2} align="center">
          Köszönjük, hogy a kemszam.hu-t választottad.
          <br /> Mostantól 365 napig hozzáférsz a feladatokhoz.
        </Typography>
      </Box>
      <Footer />
    </div>
  );
};

export default SuccessPage;
