import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import Button from "@mui/material/Button";

const LoginButton = ({ 
    text = "Bejelentkezés", 
    variant = "contained",
    customSx = {},
    redirectUri = `${window.location.origin}/app`
}) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      variant={variant}
      onClick={() =>
        loginWithRedirect({
          redirectUri,
        })
      }
      sx={{
        backgroundColor: variant === "contained" ? "#1976d2" : "transparent", // Default for contained variant
        color: variant === "contained" ? "white" : "#1976d2", // Default for outlined or custom
        fontWeight: "bold",
        textTransform: "none",
        border: variant === "outlined" ? "1px solid #1976d2" : "none", // Border for outlined variant
        "&:hover": {
          backgroundColor: variant === "contained" ? "#1565c0" : "#e3f2fd",
          borderColor: variant === "outlined" ? "#1565c0" : "none",
        },
        ...customSx, // Allow additional custom styles to override defaults
      }}
    >
      {text}
    </Button>
  );
};

export default LoginButton;
