import React from 'react';
import { Button, TextField, IconButton, Box, Typography, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';

const ExerciseNavigator = ({
  exerciseId,
  maxId,
  handlePreviousExercise,
  handleNextExercise,
  handleExerciseIdChange,
  handleKeyPress,
  handleGoToExercise,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', sm: 'row' }} // Column on small screens, row on larger screens
      alignItems="center"
      justifyContent="center"
      mt={6}
      sx={{
        textAlign: { xs: 'center', sm: 'left' }, // Center text on small screens
      }}
    >
      <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 0 }}>
        <IconButton onClick={handlePreviousExercise}>
          <ArrowBackIosIcon />
        </IconButton>
        <TextField
          type="number"
          placeholder="42"
          value={exerciseId}
          onChange={(e) => {
            const inputValue = e.target.value;

            // Allow empty input or valid number ranges
            if (inputValue === '' || (parseInt(inputValue, 10) >= 1 && (!maxId || parseInt(inputValue, 10) <= maxId))) {
              handleExerciseIdChange(inputValue);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleKeyPress();
            }
          }}
          onBlur={(e) => {
            const inputValue = parseInt(e.target.value, 10);

            // Reset to valid range if input is invalid on blur
            if (isNaN(inputValue) || inputValue < 1) {
              handleExerciseIdChange(1);
            } else if (maxId && inputValue > maxId) {
              handleExerciseIdChange(maxId);
            }
          }}
          variant="outlined"
          size="small"
          style={{ marginRight: '10px', width: '90px' }}
          inputProps={{
            min: 1, // UI constraint for better experience
            max: maxId,
          }}
        />
        {maxId && <Typography variant="body1">{`/ ${maxId}`}</Typography>}
        <IconButton onClick={handleNextExercise}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size={isSmallScreen ? 'small' : 'medium'} // Change button size based on screen size
        onClick={handleGoToExercise}
        sx={{
          marginTop: { xs: 2, sm: 0 },
          marginLeft: { xs: 0, sm: '10px' },
          alignSelf: { xs: 'center', sm: 'auto' }, // Center button on small screens
        }}
      >
        Ugrás a feladathoz
      </Button>
    </Box>
  );
};

export default ExerciseNavigator;
