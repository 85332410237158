import React from 'react';
import { Container, Grid, Divider, Box, Typography, Paper } from '@mui/material';
import { FitnessCenter, MenuBook, BarChart, Calculate, CheckCircle, VideoLibrary, AllInclusive, Apps, AssignmentTurnedIn } from '@mui/icons-material';
import LoginButton from '../components/Login';
import RegisterButton from '../components/RegisterButton';

const pricingOptions = [
  {
    title: 'Feladatgyűjtemény',
    price: '6 990Ft',
    features: [
      { icon: <FitnessCenter style={{ color: '#ff9800' }} />, text: 'Számolási feladatok az alapoktól az emelt érettségi szintig' },
      { icon: <CheckCircle style={{ color: '#4caf50' }} />, text: 'Részletes megoldás egy gombnyomásnyira' },
      { icon: <MenuBook style={{ color: '#3f51b5' }} />, text: 'Részletes fejezetenkénti leírások' },
      { icon: <BarChart style={{ color: '#673ab7' }} />, text: 'Statisztikák arról, hogyan haladsz' },
      { icon: <Calculate style={{ color: '#009688' }} />, text: 'KémiaKalkulátor: segítség számolások levezetéséhez' },
    ],
  },
  {
    title: 'FGY + Videókurzus',
    price: '29 990Ft',
    features: [
      { icon: <Apps style={{ color: '#e91e63' }} />, text: 'Minden, amit a feladatgyűjtemény csomag tartalmaz' },
      { icon: <VideoLibrary style={{ color: '#ff5722' }} />, text: '67 videó rövid, könnyen emészthető videó' },
      { icon: <AssignmentTurnedIn style={{ color: '#3f51b5' }} />, text: 'Több, mint 200 feladat részletes megoldása' },
      { icon: <AllInclusive style={{ color: '#9c27b0' }} />, text: 'A videók lefedik a teljes emelt érettségi anyagot' },
    ],
  },
];

const YoutubeSection = () => {
  return (
    <Container className="section">
      <Box className="section-content two-column" style={{ marginBottom: '100px', alignItems: 'center' }}>
        <div className="text-wrapper" style={{ flex: 1, paddingRight: '40px' }}>
          <h2 className="how-to-use-title" style={{ marginBottom: '20px', textAlign: 'left' }}>
            Fedezd fel YouTube csatornánkat!
          </h2>
          <div className="text" style={{ fontSize: '1.1rem', lineHeight: '1.6', textAlign: 'left' }}>
            <p>
              <a href="https://www.youtube.com/@lvlmeup" target="_blank" rel="noopener noreferrer">YouTube csatornánkon</a> minden témakörben találhatsz érettségi feladatmegoldó videókat.
              Ezek érthető, részletes magyarázatokat adnak, jobban segítve a megértést, mint a hivatalos megoldókulcsok.
              <br></br>Ha gondolkozol, hogy érdemes-e befizetned a nagyobb, videókat is tartalmazó csomagra, nézz körül a csatornán,
              hogy lásd, milyen jellegű videókra fizetsz elő. Természetesen a <b>kémszám.hu</b> oldalon található videók az alapoktól
              indulnak és minden témakört felölelnek.
            </p>
          </div>
        </div>

        <div className="image" style={{ flex: 1, textAlign: 'center' }}>
          <iframe
            src="https://www.youtube.com/embed/dwO_P_SR5Zk?si=IR9B5hjrowxc3Sg4"
            title="YouTube video"
            style={{
              width: '80%',
              maxWidth: '480px',
              height: '225px',
              borderRadius: '8px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Box>
    </Container>
  );
};

const Csomagok = () => {
  return (
    <Container sx={{ mt: 5, mb: 10, textAlign: 'center' }}>
      <Box>
        <h1>Elérhető előfizetési lehetőségek:</h1>
        <Divider
          sx={{
            width: '60%',
            height: '1.5px',
            backgroundColor: 'black',
            margin: '10px auto 40px',
            borderRadius: '4px',
          }}
        />
      </Box>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        {pricingOptions.map((option, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <Paper
              elevation={3}
              sx={{
                borderRadius: '12px',
                overflow: 'hidden',
                textAlign: 'center',
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '92%',
                maxWidth: '300px',
                margin: '0 auto',
                backgroundColor: '#f9f9f9',
              }}
            >
              <Box
                sx={{
                  backgroundColor: option.title === 'FGY + Videókurzus' ? '#f435ac' : '#2cb48c',
                  color: '#fff',
                  padding: '10px 0',
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  {option.title}
                </Typography>
              </Box>
              <Box mt={1} mb={1}>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  sx={{ color: '#333', mb: 0.5 }}
                >
                  {option.price}
                </Typography>
                <Typography variant="body2" sx={{ color: '#777' }}>
                  Egy éves (365 napos) hozzáférés
                </Typography>
              </Box>
              <Box mt={1} flexGrow={1}>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  align="left"
                  gutterBottom
                  sx={{ color: '#333', mt: 0 }}
                >
                  Tartalom:
                </Typography>
                <ul style={{ textAlign: 'left', listStyle: 'none', padding: 0 }}>
                  {option.features.map((feature, i) => (
                    <li
                      key={i}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '15px',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '1rem',
                          marginRight: '10px',
                        }}
                      >
                        {feature.icon}
                      </span>
                      <Typography variant="body1">{feature.text}</Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {/* Add the new text section here */}
      <Box mt={6} mb={2} textAlign="center">
        <Typography
          variant="h6"
          sx={{
            fontSize: '1.2rem',
            lineHeight: '1.6',
          }}
        >
          A vásárláshoz regisztrálj, vagy jelentkezz be!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2, // Adds spacing between the buttons
            mt: 2, // Adds margin-top for spacing from the text above
          }}
        >
          <RegisterButton
            text="Regisztrálok"
            variant="contained" // Match the variant
            customSx={{
              backgroundColor: "#1976d2",
              color: "white",
              "&:hover": {
                backgroundColor: "#1565c0",
              },
            }}
          />
          <LoginButton 
            text="Bejelentkezem" 
            variant="outlined"
            redirectUri={`${window.location.origin}/app/billing-form`}
          />
        </Box>
      </Box>
      <Divider style={{ marginTop: '50px', marginBottom: '20px', width: '100%', borderTop: '15px solid #ccc' }} />
      <YoutubeSection />
    </Container>
  );
};

export default Csomagok;
