import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Box, Grid, CircularProgress, Tab, Tabs, Button, Pagination } from '@mui/material';
import CustomCard from '../components/Card';
import StartPaymentButton from '../components/StartPaymentButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './Fejezetek.css';

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Feladatok = () => {
  const { isAuthenticated, user } = useAuth0();
  const [error, setError] = React.useState(null);
  const [exercises, setExercises] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [hasAccess, setHasAccess] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const itemsPerPage = 25;
  const initialPage = parseInt(searchParams.get('page') || '1', 10);
  const [currentPage, setCurrentPage] = React.useState(initialPage);

  const navigate = useNavigate();

  const generateRandomNumber = (maxId) => {
    return Math.floor(Math.random() * maxId) + 1;
  };

  const handleNavigateToNavigator = (exerciseNumber) => {
    const basePath = process.env.REACT_APP_BASE_PATH || ''; // Default to an empty string for production
    const navigatorPath = `${basePath}/navigator`;
    console.log(`Navigating to: ${navigatorPath}/${exerciseNumber}`);
    navigate(`${navigatorPath}/${exerciseNumber}`);
  };

  React.useEffect(() => {
    const fetchExercises = async () => {
      setLoading(true);
      setError(null);
      try {
        let url = `${apiUrl}/exercises`;
        const filter = valueToFilter(value);
        if (filter !== 'all' && user) {
          url = `${apiUrl}/responses?user_id=${encodeURIComponent(user.sub)}&response_status=${filter}`;
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch exercises');
        }
        const data = await response.json();
        setExercises(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchExercises();
    }
  }, [isAuthenticated, user, value]);

  React.useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);
        } catch (error) {
          console.error('Error checking access:', error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentPage(1); // Reset to first page when changing tabs
    setSearchParams({ page: 1 }); // Reflect page in URL
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setSearchParams({ page }); // Update the URL with the current page
  };

  const valueToFilter = (value) => {
    switch (value) {
      case 1:
        return 'nem_ment';
      case 2:
        return 'sikerult';
      case 3:
        return 'meg_nem_probaltam';
      default:
        return 'all';
    }
  };

  const getNoExercisesMessage = () => {
    switch (value) {
      case 1:
        return 'Még egyetlen feladatot sem jelöltél meg, ami nem ment.';
      case 2:
        return 'Még egyetlen feladatot sem jelöltél meg, amit sikeresen megoldottál.';
      case 3:
        return 'Már minden feladatot megnéztél. Ha úgy érzed még nem volt elég, csináld meg őket újra. Javaslom, hogy kezdd azokkal, amik nem mentek.';
      default:
        return 'Nincsenek elérhető feladatok.';
    }
  };

  const paginatedExercises = exercises.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (!isAuthenticated) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          A feladatok megtekintéséhez kérlek jelentkezz be!
        </Typography>
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton text="Megvásárolom (6.990 Ft / év)" />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleNavigateToNavigator(generateRandomNumber(exercises.length))}
        >
          Dobj egy random feladatot!
        </Button>
      </Box>
      <Box sx={{ width: '100%' }} mt={3} paddingBottom={10}>
        <Box className="tabs-container">
          <Box className="tabs-wrapper">
            <Tabs
              value={value}
              onChange={handleChange}
              centered
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
            >
              <Tab label="Összes" {...a11yProps(0)} className="tab-root" />
              <Tab label="Nem sikerült" {...a11yProps(1)} className="tab-root" />
              <Tab label="Sikerült" {...a11yProps(2)} className="tab-root" />
              <Tab label="Nem próbáltam" {...a11yProps(3)} className="tab-root" />
            </Tabs>
          </Box>
        </Box>

        {[0, 1, 2, 3].map((index) => (
          <CustomTabPanel value={value} index={index} key={index}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {exercises.length === 0 ? (
                  <Typography>{getNoExercisesMessage()}</Typography>
                ) : (
                  <>
                    <Grid container spacing={3}>
                      {paginatedExercises.map((exercise) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={exercise.id}>
                          <CustomCard exercise={exercise} onClick={() => handleNavigateToNavigator(exercise.id)} />
                        </Grid>
                      ))}
                    </Grid>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        count={Math.ceil(exercises.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </CustomTabPanel>
        ))}
      </Box>
      {error && <Typography color="error">{error}</Typography>}
    </div>
  );
};

export default Feladatok;
