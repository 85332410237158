import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from './Login';
import LogoutButton from './Logout';
import Profile from './Profile';
import StartPaymentButton from './StartPaymentButton';
import RegisterButton from './RegisterButton';

const Navbar = () => {
    const { isAuthenticated, user } = useAuth0();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showHamburger, setShowHamburger] = useState(false);
    const [hasAccess, setHasAccess] = useState(false);
    const dropdownRef = useRef(null);

    const basePath = process.env.REACT_APP_BASE_PATH || ""; // Get base path from environment variables

    const links = [
    { path: "/feladatok", label: "FELADATOK" },
    { path: "/fejezetek", label: "FEJEZETEK" },
    { path: "/statisztikak", label: "STATISZTIKÁK" },
    { path: "/videok", label: "VIDEÓK" },
    { path: "/kemiakalkulator", label: "KÉMIAKALKULÁTOR" },
    ];

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleHamburger = () => {
        setShowHamburger(!showHamburger);
    };

    const closeHamburger = () => {
        setShowHamburger(false);
    };

    useEffect(() => {
        const checkAccess = async () => {
            if (isAuthenticated && user) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_PAYMENT_API_URL}/check-access`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ email: user.email }),
                    });
                    const data = await response.json();
                    setHasAccess(data.has_access);
                } catch (error) {
                    console.error("Error checking access:", error);
                    setHasAccess(false);
                }
            }
        };

        checkAccess();
    }, [isAuthenticated, user]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/" onClick={closeHamburger}>
                    {/* Desktop Logo */}
                    <img src="/assets/logo.png" alt="Logo" className="desktop-logo" />
                    {/* Mobile Logo */}
                    <img src="/assets/kemszam_logo_favicon.png" alt="Mobile Logo" className="mobile-logo" />
                </Link>
            </div>
            <div className={`navbar-user ${showHamburger ? 'active' : ''}`}>
                <div className="navbar-menu-icon" onClick={toggleHamburger}>
                    &#9776;
                </div>
                {isAuthenticated ? (
                    <div className="profile-container" ref={dropdownRef}>
                        <img 
                            src={user.picture} 
                            alt={user.name} 
                            className="profile-picture" 
                            onClick={toggleDropdown} 
                        />
                        {showDropdown && (
                            <div className="dropdown-menu">
                                <Profile />
                                {!hasAccess && <StartPaymentButton text="Megvásárolom"/>}
                                <LogoutButton />
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={`navbar-login-mobile ${showHamburger ? 'hidden' : ''}`}>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <RegisterButton />
                            <LoginButton />
                        </div>
                    </div>
                )}
            </div>
            <div className={`navbar-links-container ${showHamburger ? 'active' : ''}`}>
                <ul className="navbar-links">
                    {links.map((link) => (
                    <li key={link.path}>
                        <Link to={`${basePath}${link.path}`} onClick={closeHamburger}>
                        {link.label}
                        </Link>
                    </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
