import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CustomCard = ({ exercise }) => {
  const navigate = useNavigate();

  const truncatedText =
    exercise.exercise.length > 98
      ? exercise.exercise.substring(0, 98) + '...'
      : exercise.exercise;

  const handleClick = () => {
    // Get the base path and append '/navigator'
    const basePath = process.env.REACT_APP_BASE_PATH || ""; // Default to an empty string
    const navigatorPath = `${basePath}/navigator`;
    navigate(`${navigatorPath}/${exercise.number}`);
  };

  return (
    <Card sx={{ boxShadow: 4, cursor: 'pointer', height: 125 }} onClick={handleClick}>
      <CardContent>
        <Typography variant="body1" component="div" sx={{ textAlign: 'left' }}>
          <strong>{exercise.number}. feladat:</strong>{" "}
          <span dangerouslySetInnerHTML={{ __html: truncatedText }} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
