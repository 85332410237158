import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';

const FailurePage = () => {
  const { user, isLoading } = useAuth0();

  useEffect(() => {
    if (user) {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      const errorMessage = urlParams.get('error_message') || 'Unknown error';

      const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;

      axios.post(`${paymentApiUrl}/store-payment-info`, {
        email: user.email,
        user_id: user.sub,
        session_id: sessionId,
        payment_status: 'failed',
        error_message: errorMessage,
      }).then(response => {
        console.log('Payment info stored successfully');
      }).catch(error => {
        console.error('Error storing payment info:', error);
      });
    }
  }, [user]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px', paddingBottom: '100px' }}>
      <Box mt={10}>
        <Typography variant="h4" align="center"><strong>Sikertelen fizetés!</strong></Typography>
        <Typography mt={2} align="center">
          A fizetés során valamilyen probléma lépett fel. Kérlek, próbáld meg később újra.
          <br /> Vagy keress meg minket az <a href="mailto:info@kemszam.hu">info@kemszam.hu</a> e-mail címen és megoldjuk máshogy.
        </Typography>
      </Box>
    </div>
  );
};

export default FailurePage;
