import React from "react";
import Button from "@mui/material/Button";

const RegisterButton = ({ text = "Regisztráció", customSx = {}, variant = "contained" }) => {
  const handleRegister = () => {
    const authDomain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const basePath = process.env.REACT_APP_BASE_PATH || "";
    const redirectUri = `${window.location.origin}${basePath}/sikeres-regisztracio`;

    if (!authDomain || !clientId) {
      console.error("Missing AUTH0_DOMAIN or AUTH0_CLIENT_ID in environment variables.");
      return;
    }

    const url = `https://${authDomain}/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&screen_hint=signup&response_type=code&scope=openid%20profile%20email`;

    window.location.href = url; // Redirect the user
  };

  return (
    <Button
      variant={variant}
      onClick={handleRegister}
      sx={{
        backgroundColor: "#2cb48c",
        color: "white",
        fontWeight: "bold",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#1f8062",
        },
        ...customSx, // Apply custom styles if provided
      }}
    >
      {text}
    </Button>
  );
};

export default RegisterButton;
