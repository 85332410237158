import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Button from '@mui/material/Button';

const NavbarMarketing = () => {
    const [showHamburger, setShowHamburger] = useState(false);
    const dropdownRef = useRef(null);

    const toggleHamburger = () => {
        setShowHamburger(!showHamburger);
    };

    const closeHamburger = () => {
        setShowHamburger(false);
    };

    const handleAppNavigation = () => {
        const basePath = process.env.REACT_APP_BASE_PATH || '/app';
        const appUrl = `${window.location.origin}${basePath}`;
        window.location.href = appUrl;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowHamburger(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/" onClick={closeHamburger}>
                    {/* Desktop Logo */}
                    <img src="/assets/logo.png" alt="Logo" className="desktop-logo" />
                    {/* Mobile Logo */}
                    <img src="/assets/kemszam_logo_favicon.png" alt="Mobile Logo" className="mobile-logo" />
                </Link>
            </div>
            <div className={`navbar-user ${showHamburger ? 'active' : ''}`}>
                <div className="navbar-menu-icon" onClick={toggleHamburger}>
                    &#9776;
                </div>
                <div className={`navbar-login-mobile ${showHamburger ? 'hidden' : ''}`}>
                    <div className="navbar-marketing-button">
                        <Button
                            variant="contained"
                            onClick={handleAppNavigation}
                            sx={{
                                color: "white",
                                fontWeight: "bold",
                                textTransform: "none",
                            }}
                        >
                            Irány a feladatgyűjtemény
                        </Button>
                    </div>
                </div>
            </div>
            <div className={`navbar-links-container ${showHamburger ? 'active' : ''}`}>
                <ul className="navbar-links">
                    <li><Link to="/" onClick={closeHamburger}>FŐOLDAL</Link></li>
                    <li><Link to="/rolunk" onClick={closeHamburger}>RÓLUNK</Link></li>
                    <li><Link to="/csomagok" onClick={closeHamburger}>CSOMAGOK</Link></li>
                    <li><Link to="/kapcsolat" onClick={closeHamburger}>KAPCSOLAT</Link></li>
                </ul>
            </div>
        </nav>
    );
};

export default NavbarMarketing;
