import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import StartPaymentButton from '../components/StartPaymentButton';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './Videos.css';
import UpgradeButton from '../components/UpgradeButton';

const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const apiUrl = process.env.REACT_APP_API_URL;
const videoBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;
const videosApiUrl = `${apiUrl}/videos`;

const Videos = () => {
  const { isAuthenticated, user } = useAuth0();
  const [hasAccess, setHasAccess] = useState(false);
  const [userPlan, setUserPlan] = useState(null); // Track the user's plan
  const [videos, setVideos] = useState([]);
  const [loadedVideos, setLoadedVideos] = useState({}); // Tracks which videos are loaded
  const [loading, setLoading] = useState(false); // Tracks loading for PaymentButton

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          // Check user access
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });

          const data = await response.json();
          setHasAccess(data.has_access);
          setUserPlan(data.plan); // Set the user's plan from the backend

          // Fetch videos if access is granted and the plan is valid
          if (data.has_access && data.plan === 'exercises_with_videos') {
            const videoResponse = await fetch(videosApiUrl);
            if (!videoResponse.ok) {
              throw new Error('Failed to fetch videos');
            }

            const videoData = await videoResponse.json();
            setVideos(videoData);
          }
        } catch (error) {
          console.error('Error checking access or fetching videos:', error);
          setHasAccess(false); // Deny access in case of an error
          setUserPlan(null); // Reset the plan in case of an error
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  // Load video URL when play button is clicked
  const handlePlay = (videoId) => {
    setLoadedVideos((prev) => ({ ...prev, [videoId]: true }));
  };

  // Show login prompt if user is not authenticated
  if (!isAuthenticated) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          A videók megtekintéséhez kérlek jelentkezz be!
        </Typography>
      </Box>
    );
  }

  // Show StartPaymentButton if user doesn't have access
  if (!hasAccess) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton
          text="Megvásárolom (29.990 Ft / év)"
          customStyles={{
              backgroundColor: '#f435ac',
              '&:hover': {
                  backgroundColor: '#d12f95',
              },
          }}
        />
      </Box>
    );
  }

  // Show PaymentButton if user has access but the plan is not "exercises_with_videos"
  if (hasAccess && userPlan !== 'exercises_with_videos') {
    const billingInfo = {
      billing_name: user.name,
      plan: 'exercises_with_videos',
    };

    return (
      <Box>
        <Typography mt={10} mb={3}>
          A videók eléréséhez frissítsd az előfizetésed a videók elérését is magában foglaló csomagra!
        </Typography>
        <UpgradeButton/>
      </Box>
    );
  }

  // Show videos if user has access and the correct plan
  return (
    <div style={{ margin: '0 auto', maxWidth: '1100px', padding: '20px' }}>
      <Box mt={2} mb={6}>
        {videos.map((video, index) => (
          <Box key={video.id} mb={4}>
            <Typography variant="h5" align="center" marginBottom="20px">
              {`${video.number}. ${video.title}`}
            </Typography>
            <Box sx={{ maxWidth: 600, width: '100%', margin: '0 auto' }}>
              {/* Dynamically load video source when play button is clicked */}
              {loadedVideos[video.id] ? (
                <Plyr
                  source={{
                    type: 'video',
                    sources: [{ src: `${videoBaseUrl}${video.URL}`, type: 'video/mp4' }],
                  }}
                  options={{ autoplay: true }}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                    backgroundColor: '#000',
                    cursor: 'pointer',
                  }}
                  onClick={() => handlePlay(video.id)}
                >
                  <Typography variant="body1" color="white">
                    Kattints ide a videó betöltéséhez
                  </Typography>
                </Box>
              )}
            </Box>
            {index < videos.length - 1 && (
              <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Videos;
