import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

const StartPaymentButton = ({ text, customStyles }) => {
    const navigate = useNavigate();

    const basePath = process.env.REACT_APP_BASE_PATH || '';
    
    const handleClick = () => {
        navigate(`${basePath}/billing-form`);
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            style={{ minWidth: 'auto' }}
            sx={{
                ...customStyles,
            }}
        >
            {text}
        </Button>
    );
};

export default StartPaymentButton;
