import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams, useNavigate } from 'react-router-dom';
import ExerciseNavigator from '../components/ExerciseNavigator';
import ExerciseContent from '../components/ExerciseContent';
import './Navigator.css';
import LoginButton from '../components/Login';
import StartPaymentButton from '../components/StartPaymentButton';
import useExerciseData from '../hooks/useExerciseData';

const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const basePath = process.env.REACT_APP_BASE_PATH || "";
const navigatorPath = `${basePath}/navigator`;

const Navigator = () => {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const { exerciseId: paramExerciseId } = useParams();
  const [hasAccess, setHasAccess] = useState(false);
  const [inputExerciseId, setInputExerciseId] = useState('');

  const {
    exerciseId,
    maxId,
    exerciseContent,
    handlePreviousExercise: originalHandlePreviousExercise,
    handleNextExercise: originalHandleNextExercise,
    handleRandomExercise,
    setExerciseId,
    fetchExercise,
    showSolution,
  } = useExerciseData();

  const fetchAndSetExercise = useCallback((id) => {
    setExerciseId(id);
    fetchExercise(id);
  }, [setExerciseId, fetchExercise]);

  useEffect(() => {
    if (paramExerciseId) {
      fetchAndSetExercise(paramExerciseId);
      setInputExerciseId(paramExerciseId);
    }
  }, [paramExerciseId, fetchAndSetExercise]);

  useEffect(() => {
    if (isAuthenticated && user) {
      const checkAccess = async () => {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);
        } catch (error) {
          console.error("Error checking access:", error);
          setHasAccess(false);
        }
      };
      checkAccess();
    }
  }, [isAuthenticated, user]);

  const handleInputChange = (newInputExerciseId) => {
    setInputExerciseId(newInputExerciseId);
  };

  const handleExerciseIdUpdate = () => {
    fetchAndSetExercise(inputExerciseId);
    navigate(`${navigatorPath}/${inputExerciseId}`);
  };

  const handlePreviousExercise = () => {
    const previousExerciseId = Math.max(1, parseInt(exerciseId) - 1); // Ensure it doesn't go below 1
    fetchAndSetExercise(previousExerciseId);
    navigate(`${navigatorPath}/${previousExerciseId}`);
    setInputExerciseId(previousExerciseId);
  };
  
  const handleNextExercise = () => {
    const nextExerciseId = maxId ? Math.min(maxId, parseInt(exerciseId) + 1) : parseInt(exerciseId) + 1; // Ensure it doesn't exceed maxId
    fetchAndSetExercise(nextExerciseId);
    navigate(`${navigatorPath}/${nextExerciseId}`);
    setInputExerciseId(nextExerciseId);
  };

  if (!isAuthenticated) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          A feladatok megtekintéséhez kérlek jelentkezz be!
        </Typography>
        <LoginButton />
      </Box>
    );
  }

  if (!hasAccess) {
    return (
      <Box>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton text="Megvásárolom (6.990 Ft / év)" />
      </Box>
    );
  }

  const userId = user?.sub; // Fetch the userId from Auth0 user object

  return (
    <div>
      <Box>
        <ExerciseNavigator
          exerciseId={inputExerciseId}
          maxId={maxId}
          handlePreviousExercise={handlePreviousExercise}
          handleNextExercise={handleNextExercise}
          handleRandomExercise={handleRandomExercise}
          handleExerciseIdChange={handleInputChange}
          handleKeyPress={handleExerciseIdUpdate}
          handleBlur={handleExerciseIdUpdate}
          handleGoToExercise={handleExerciseIdUpdate}
        />
        <Box id="exerciseContainer">
          <ExerciseContent 
            exerciseContent={exerciseContent} 
            showSolution={showSolution} 
            userId={userId}
          />
        </Box>
      </Box>
    </div>
  );
};

export default Navigator;
